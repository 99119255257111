import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import './style.css'
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

const VideoPlayer = (props) => {

  const shortVideos = [
    {
      title: "Taleem Ul Quran by Sheikh Saeed",
      videoSrc: "https://archive.org/download/sheikh-saeed-short-videos/Sheikh%20Saeed%20%20Short%20Video%20%20%281%29.mp4"
    },
    {
      title: "Taleem Ul Quran by Sheikh Saeed",
      videoSrc: "https://archive.org/download/sheikh-saeed-short-videos/Sheikh%20Saeed%20%20Short%20Video%20%20%2810%29.mp4"
    },
    {
      title: "Taleem Ul Quran by Sheikh Saeed",
      videoSrc: "https://archive.org/download/sheikh-saeed-short-videos/Sheikh%20Saeed%20%20Short%20Video%20%20%2811%29.mp4"
    },
    {
      title: "Taleem Ul Quran by Sheikh Saeed",
      videoSrc: "https://archive.org/download/sheikh-saeed-short-videos/Sheikh%20Saeed%20%20Short%20Video%20%20%2812%29.mp4"
    },
    {
      title: "Taleem Ul Quran by Sheikh Saeed",
      videoSrc: "https://archive.org/download/sheikh-saeed-short-videos/Sheikh%20Saeed%20%20Short%20Video%20%20%282%29.mp4"
    },
    {
      title: "Taleem Ul Quran by Sheikh Saeed",
      videoSrc: "https://archive.org/download/sheikh-saeed-short-videos/Sheikh%20Saeed%20%20Short%20Video%20%20%283%29.mp4"
    },
    {
      title: "Taleem Ul Quran by Sheikh Saeed",
      videoSrc: "https://archive.org/download/sheikh-saeed-short-videos/Sheikh%20Saeed%20%20Short%20Video%20%20%284%29.mp4"
    },
    {
      title: "Taleem Ul Quran by Sheikh Saeed",
      videoSrc: "https://archive.org/download/sheikh-saeed-short-videos/Sheikh%20Saeed%20%20Short%20Video%20%20%285%29.mp4"
    },
    {
      title: "Taleem Ul Quran by Sheikh Saeed",
      videoSrc: "https://archive.org/download/sheikh-saeed-short-videos/Sheikh%20Saeed%20%20Short%20Video%20%20%286%29.mp4"
    },
    {
      title: "Taleem Ul Quran by Sheikh Saeed",
      videoSrc: "https://archive.org/download/sheikh-saeed-short-videos/Sheikh%20Saeed%20%20Short%20Video%20%20%287%29.mp4"
    },
    {
      title: "Taleem Ul Quran by Sheikh Saeed",
      videoSrc: "https://archive.org/download/sheikh-saeed-short-videos/Sheikh%20Saeed%20%20Short%20Video%20%20%288%29.mp4"
    },
    {
      title: "Taleem Ul Quran by Sheikh Saeed",
      videoSrc: "https://archive.org/download/sheikh-saeed-short-videos/Sheikh%20Saeed%20%20Short%20Video%20%20%289%29.mp4"
    }
  ]

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  }

  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    cursor: 'pointer',
    marginBottom: '20px'
  }));

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="service-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-9 col-md-12 col-12">
            <div className="service-single-content">
              <h2>Short Videos</h2>
              <p> Short Video Clips for social media sharing.</p>
            </div>
            {isLoading ? (
              <div className="loader">
                <CircularProgress />
                <Typography variant="h6">Loading...</Typography>
              </div>
            ) : (
              ''
            )}
            <Box sx={{ width: '100%' }}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {shortVideos.map((shortVideo, key) => {
                  return (
                    <>
                      <Grid xs={12} md={6} key={key}>
                        {/* <Item elevation={3}> */}
                          <video
                            className='video-style'
                            width={371}
                            controls
                            src={shortVideo.videoSrc}
                          />
                        {/* </Item> */}
                      </Grid>
                    </>
                  )
                })}
              </Grid>
            </Box>
          </div>
          <div className="col col-lg-3 col-md-12 col-12">
            <div className="service-sidebar">
              <div className="widget service-list-widget">
                <h3>All Formates</h3>
                <ul>
                  <li><Link to="/audio">Audio Formate</Link></li>
                  <li><Link onClick={ClickHandler} to="/video">Video Formate</Link></li>
                  <li><Link onClick={ClickHandler} to="/pdf">PDF Formate</Link></li>
                  <li className="current"><Link onClick={ClickHandler} to="#">Short Videos</Link></li>
                  <li><Link onClick={ClickHandler} to="/404">Posters and Wallpapers</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VideoPlayer;