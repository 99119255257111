import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import VideoPlayer from '../../components/VideoPlayer';


const ShortVideos =() => {
    return(
        <Fragment>
            <Navbar/>
            {/* <PageTitle pageTitle={'Short Videos'} pagesub={'Short clips for social media'}/>  */}
            <VideoPlayer/>
            <Footer footerClass={'wpo-ne-footer-2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ShortVideos;
